import _get from './get';
import _post from './post';
import _put from './put';
import _delete from './delete';

// Import Managers
import VideoLikesManager from './likes';
import VideoRatingManager from './ratings';
import VideoTranscriptManager from './transcript';

const VideoManager = {
	_get,
	_post,
	_put,
	_delete,
	VideoLikesManager,
	VideoRatingManager,
	VideoTranscriptManager
};

export default VideoManager;
