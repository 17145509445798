export const SPECIALITY_DATA = [
  "Cardiology",
  "Consulting Physician",
  "Critical Care Medicine",
  "Dentistry",
  "Dermatology",
  "Diabetology",
  "Endocrinology",
  "ENT",
  "Gastroenterology",
  "General Physician",
  "Hepatology",
  "Infectious Diseases",
  "Medicine",
  "Musculoskeletal Diseases",
  "Nephrology",
  "Neurology",
  "Neuropsychiatry",
  "Obstetrics & Gynaecology",
  "Oncology",
  "Opthalmology",
  "Pediatrics",
  "Pulmonology",
  "Surgery",
  "Urology",
  "Gynecology",
  "Veterinary",
  "Others",
];

export const VIDEO_ROW_SPECIALITY_DATA = [
  "Cardiology",
  "Diabetology",
  "Consulting Physician",
  "General Physician",
  "Gynecology",
];
