//Imports
import { VIDEO_COLLECTION, LIKES_COLLECTION, RATINGS_COLLECTION } from '../../AppConstants/CollectionConstants';
import { SourceType, VideoType } from '../../AppConstants/TypeConstants';

// Firebase
import { firestore } from '../../firebase/firebase';

// Todo
// Remove all the likes and ratings attached to this video

const deleteVideo = async (videoId) => {
	if (!videoId) throw new Error('videoId undefined!');
	try {
		const docRef = firestore.collection(VIDEO_COLLECTION).doc(videoId);
		await docRef.delete();
		return videoId;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

const _delete = { deleteVideo };
export default _delete;
